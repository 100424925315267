import React, { useState} from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import Navbar from "../../components/Navbar/Navbar";

const Profile = ({ selectedOption, setSelectedOption }) => {
  const [isFavorite, setIsFavorite] = useState(false);
    const navigate = useNavigate();
  // State to track the active section
  const [activeSection, setActiveSection] = useState("profile");

  // Function to handle click and set active section
  const handleSectionClick = (section) => {
    setActiveSection(section);
  };
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => setIsOpen((prev) => !prev);

  const handleOptionClick = (option) => {
    setSelectedOption(option.name);
    setIsOpen(false);
  };

  const optionData = [
    { name: "Shortlisted" },
    { name: "Interviewed" },
    { name: "Hired" },
    { name: "Rejected" },
  ];
  const tabs = [
    { name: "profile", label: "PROFILE" },
    { name: "recent", label: "RECENT ACTIVITIES" },
    { name: "jobs", label: "JOBS APPLIED" },
    { name: "insights", label: "TALENTIN INSIGHTS" },
  ];
  return (
    <>
      <Helmet>
        <style>{"body { background-color: #F3F5F9; }"}</style>
      </Helmet>
      <Box sx={{ display: "flex" }}>
        <Navbar />
        <Box
          className="px-6 pt-6"
          component="main"
          sx={{ flexGrow: 1, height: "100vh" }}
        >
          <div className="flex justify-between">
            <div className="flex gap-2" onClick={() => navigate(-1)}>
              <img src="/images/arrow-left.svg" />
              <div className="font-bold">Back</div>
            </div>
            <div
              className="flex gap-2 uppercase text-[#21d6aa] cursor-pointer"
              onClick={() => setIsFavorite(!isFavorite)}
            >
              <img
                src={
                  isFavorite
                    ? "/images/filled-bookmark.svg"
                    : "/images/bookmark.svg"
                }
                alt="icon"
              />
              <p className="text-[#21d6aa] font-medium">
                {isFavorite ? "Added to favorite" : "Add to favorite"}
              </p>
            </div>
          </div>

          {/* Profile Details */}
          <div className="w-full flex bg-[#FFFFFF] p-6 rounded-md shadow-[0px 0px 20px] mt-4">
            <div className="w-[10%]">
              <img
                className="w-[80px] h-[80px]"
                src="/images/profile-icon.svg"
              />
            </div>
            <div className="w-[22%]">
              <div className="flex gap-5">
                <p className="font-medium">JOHN DOE</p>
                <img src="Menu.svg" />
              </div>
              <p>Software Engineer</p>
              <p className="text-[#708ea4] text-[13px] pt-1">
                Louisville, Kentucky, United States
              </p>
              <div className="flex gap-2 pt-4">
                <img src="/PhoneIcon.svg" />
                <p>+1 (203)-456-7890</p>
              </div>
              <div className="flex gap-2 pt-2">
                <img src="/EmailIcon.svg" />
                <p>johndoe@gmail.com</p>
              </div>
            </div>
            <div className="w-[55%] flex flex-col justify-end items-start ml-10">
              <div className="flex justify-start gap-10">
                <img src="/Group 247.svg" />
                {activeSection === "profile" && (
                  <div className="flex gap-10">
                    <img src="/Delete.svg" />
                    <img src="/AddIcon.svg" />
                  </div>
                )}
              </div>

              <div className="flex justify-start gap-10 mt-4">
                <img src="/Group 247.svg" />
                {activeSection === "profile" && (
                  <div className="flex gap-10">
                    <img src="/Delete.svg" />
                    <img src="/AddIcon.svg" />
                  </div>
                )}
              </div>
            </div>
            {/* Dropdown */}
            <div className="relative w-36 ">
              <button
                onClick={toggleDropdown}
                className="w-full flex text-sm justify-between items-center px-4 py-1.5  rounded-lg shadow-lg bg-white"
              >
                {selectedOption || "Sourced"}
                <img
                  src="images/custom-arrow.svg"
                  alt="Dropdown Arrow"
                  className={`transition-transform ${
                    isOpen ? "rotate-180" : ""
                  }`}
                />
              </button>

              {isOpen && (
                <div
                  style={{ scrollbarWidth: "none" }}
                  className="absolute mt-2 w-full px-4 text-sm bg-white border h-[110px] rounded-lg shadow-lg max-h-40 overflow-auto"
                >
                  {optionData.map((option) => (
                    <p
                      key={option.id}
                      onClick={() => handleOptionClick(option)}
                      className=" py-1.5  cursor-pointer border-b border-[#708ea4]  last:border-0"
                    >
                      {option.name}
                    </p>
                  ))}
                </div>
              )}
            </div>
          </div>

          {/* Tab Navigation */}
          <div
            style={{
              maxHeight: "calc(100vh - 280px)",
              scrollbarWidth: "none",
              overflow: "auto",
            }}
            className="w-full bg-[#FFFFFF] pb-5 px-6 rounded-md shadow-[0px 0px 20px] mt-4 "
          >
            <div className="sticky top-0 left-0 right-0 bg-white z-10 pt-5 flex gap-20 text-[#708ea4] font-medium w-full">
              {" "}
              {tabs.map((tab) => (
                <div key={tab.name} className="relative">
                  <div
                    className={`cursor-pointer pb-3 ${
                      activeSection === tab.name ? "text-[#21d6aa]" : ""
                    }`}
                    onClick={() => handleSectionClick(tab.name)}
                  >
                    {tab.label}
                  </div>
                  {activeSection === tab.name && (
                    <div className="absolute bottom-0 left-0 right-0 h-[2px] bg-[#21d6aa] z-10"></div> // Added z-index
                  )}
                </div>
              ))}
              <div className="absolute bottom-0 left-0 right-0 border-b-2"></div>
            </div>

            {/* Conditional Rendering Based on Active Section */}
            {activeSection === "profile" && (
              <>
                <div>
                  <div>
                    {/* PROFILE */}
                    <p className="font-medium text-[#5a93ed] pt-4">
                      EXPERIENCE
                    </p>
                    <p className="font-medium text-[#1f2e39] pt-3">
                      Principal Software Engineer
                    </p>
                    <p className=" text-[#1f2e39] pt-1">Full Time</p>
                    <p className="text-[#708ea4] pt-1">Nov 2018 - Dec 2024</p>
                    <p className="text-[#708ea4] pt-1 text-[12px]">
                      Louisville, Kentucky, United States
                    </p>

                    <ul className="list-disc p-5 text-[#1f2e39] text-[16px]">
                      <li>
                        Adulescens itaque omnis patrocinor soleo thesaurus
                        maxime bestia. Antiquus ad caute natus. Strues pauper
                        degenero suadeo ademptio.
                      </li>
                      <li>
                        Laudantium pauper sono desipio. Clementia compello
                        vilicus capillus tunc. Caritas canto coniuratio aggero
                        ver quae ara beatus vulticulus cotidie. Virgo comedo
                        comprehendo tempore vulpes tener. Id aeternus
                        consequuntur contego repellat angelus arma clamo timor
                        bellicus. •
                      </li>
                      <li>
                        Turbo audio abundans. Arca arto ulciscor natus ambulo
                        ante vir audentia. Cursim trepide adversus. Molestiae
                        animus conventus amicitia comes circumvenio admoveo.
                        Torrens cui numquam. •
                      </li>
                      <li>Approbo eveniet cauda bos tutamen.</li>
                    </ul>
                  </div>
                  <div className=" border-b-2">
                    <p className="font-medium text-[#1f2e39] pt-2">
                      Senior Software Engineer
                    </p>
                    <p className=" text-[#1f2e39] pt-1">Full Time</p>
                    <p className="text-[#708ea4] pt-1">Dec 2017 - Nov 2018</p>
                    <p className="text-[#708ea4] pt-1 text-[14px]">
                      St.Louis.Missouri, United States
                    </p>

                    <ul className="list-disc p-5 text-[#1f2e39] text-[16px]">
                      <li>
                        Adulescens itaque omnis patrocinor soleo thesaurus
                        maxime bestia. Antiquus ad caute natus. Strues pauper
                        degenero suadeo ademptio.
                      </li>
                      <li>
                        Laudantium pauper sono desipio. Clementia compello
                        vilicus capillus tunc. Caritas canto coniuratio aggero
                        ver quae ara beatus vulticulus cotidie. Virgo comedo
                        comprehendo tempore vulpes tener. Id aeternus
                        consequuntur contego repellat angelus arma clamo timor
                        bellicus. •
                      </li>
                      <li>
                        Turbo audio abundans. Arca arto ulciscor natus ambulo
                        ante vir audentia. Cursim trepide adversus. Molestiae
                        animus conventus amicitia comes circumvenio admoveo.
                        Torrens cui numquam. •
                      </li>
                      <li>Approbo eveniet cauda bos tutamen.</li>
                    </ul>
                  </div>
                  <div>
                    <p className="font-medium text-[#5a93ed] pt-4">EDUCATION</p>
                    <p className="font-medium text-[#1f2e39] pt-3">
                      University of Missouri - Saint Louis
                    </p>
                    <p className="text-[#1f2e39] pt-1">
                      Master of Science - MS • Cybersecurity
                    </p>
                    <p className="text-[#708ea4] pt-1">2021 – 2022</p>
                    <p className="font-medium text-[#1f2e39] pt-3">
                      Washington University - Saint Louis
                    </p>
                    <p className="text-[#1f2e39] pt-1">
                      Bachelor of Science - BS • Information Management
                    </p>
                    <p className="text-[#708ea4] pt-1">2007 – 2010</p>
                  </div>
                </div>
              </>
            )}
            {activeSection === "recent" && (
              <div>
                {/* RECENT ACTIVITIES */}

                <div className="flex mt-4 gap-2">
                  <p className=" font-medium text-[#1f2e39]">Jenna Robert </p>
                  <p>added a note.</p>
                </div>
                <div className="flex flex-row border-b-2 py-3">
                  <p className="text-[#708ea4]">
                    Suffoco sollicito desino deripio super necessitatibus. Surgo
                    thermae molestiae armarium tergeo cui demergo thema vesco...
                  </p>
                  <p className="text-[#21d6aa] font-medium">View More</p>
                </div>
                <div className=" flex gap-2 border-b-2 py-3">
                  <p className="font-medium text-[#1f2e39]">Stephanie Rogers</p>
                  <p>sent an email on johndoe@gmail.com.</p>
                </div>
                <div className="flex  gap-2 border-b-2 py-3">
                  <p className="font-medium text-[#1f2e39]">Stephanie Rogers</p>
                  <p>sent an email on johndoe@gmail.com.</p>
                </div>

                <p className="mt-4">Jenna Robert added a note.</p>
                <div className="flex  pt-3">
                  <p className="text-[#708ea4]">
                    Debeo suscipit debilito. Depromo depereo ambulo. Vis quos
                    depereo sollicito desino deripio super necessitatibus...
                  </p>
                  <p className="text-[#21d6aa] font-medium">View More</p>
                </div>
              </div>
            )}
            {activeSection === "jobs" && (
              <>
                {/* JOBS APPLIED */}
                <div className=" border-b-2 pb-2">
                  <p className=" font-medium text-[#21d6aa] pt-3">
                    JOB ID: 19730
                  </p>
                  <p className=" font-medium text-[#1f2e39] pt-2">
                    Software Engineer
                  </p>
                  <ul class=" list-disc pl-5">
                    <div className="flex pt-2">
                      <li class="marker:text-sky-500 w-[160px]">Sourced</li>
                      <p className="text-[#708ea4]">27 Dec 2024</p>
                    </div>
                    <div className="flex pt-2 ">
                      <li class="marker:text-sky-500 w-[160px]">Shortlisted</li>
                      <p className="text-[#708ea4]">4 Jan 2025</p>
                    </div>
                  </ul>
                  <div className="mt-2 flex">
                    <div className="flex gap-2">
                      <img src="/CheckIcon.svg" />
                      <p className=" text-[#5a93ed] w-[160px]">Interviewed</p>
                    </div>
                    <p className="text-[#708ea4]">15 Jan 2025</p>
                  </div>
                </div>
                <div>
                  <p className=" font-medium text-[#21d6aa] pt-3">
                    JOB ID: 20070
                  </p>
                  <p className=" font-medium text-[#1f2e39] pt-2">
                    Full Stack Engineer
                  </p>

                  <div className="mt-2 flex">
                    <div className="flex gap-2">
                      <img src="/CheckIcon.svg" />
                      <p className="w-[160px] text-[#5a93ed]">Sourced</p>
                    </div>
                    <p className="text-[#708ea4]">16 Jan 2025</p>
                  </div>
                </div>
              </>
            )}
            {activeSection === "insights" && (
              // TALENTIN INSIGHTS
              <div className="overflow-x-auto rounded-md mt-3 ">
               
                <div>
                 <div  className="flex"> <p className=" font-bold mt-2 mr-3">Rank: </p>
                 <p className=" flex items-center  justify-center  border-[#21d6aa]  border-4 w-[40px] h-[40px]  font-medium text-[12px] rounded-full">80%</p></div>
                  <p className=" font-bold mb-2 mt-2">Talentin Insights</p>
                  <p>      Product Management, Product Lifecycle Management,
                        Business Case Definition & Refinement, Goal Objective
                        and Key measures definition, Agile, Healthcare IT,
                        Project Management, Design Thinking, Sprint Planning,
                        Backlog Refinement, Story Writing, Acceptance Criteria</p>
                </div>

                <div>
                  <p className="font-bold mt-4 mb-2">Profile Summary</p>
                  <p>
                    John Doe is a highly experienced professional in the field
                    of Healthcare Management with a strong background in Product
                    Management/Product Owner roles. With over 15 years of
                    experience, including significant tenure at XYZ Care, a
                    healthcare management company, John has demonstrated
                    expertise in Product Lifecycle Management, Business Case
                    Definition & Refinement, and Goal Objective and Key measures
                    definition. His skills in Agile methodologies, project
                    management, and design thinking practices align well with
                    the job description. John's education in Computer Science
                    and certifications in Agility & Coaching and as a Certified
                    EclinicalWorks Support Trainer further complement her
                    suitability for the Product Manager role.
                  </p>
                  <div className="mt-4 flex">
                    <p>
                      <strong> Pros:</strong>
                      John has extensive experience in healthcare IT,
                      specifically with XYZ Care, demonstrating a strong
                      alignment with the required 3 years of experience in the
                      sector. His roles have encompassed product management,
                      product ownership, and systems analysis, directly matching
                      the JD's essential criteria. John's background in Agile
                      methodologies, proven by his SME status and
                      certifications, aligns with the strong knowledge of Agile
                      development methodologies required. His educational
                      background in Computer Science meets the JD's training and
                      education requirements.
                    </p>
                  </div>
                  <div className="flex mt-3">
                    <p>
                      <strong> Cons:</strong>
                      While John has demonstrated project management experience,
                      the CV does not explicitly mention 3 years of Project
                      Management experience as preferred in the JD.
                      Additionally, there is no direct mention of 1 year of
                      experience with design thinking practices, which is listed
                      as preferred in the JD.
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </Box>
      </Box>
    </>
  );
};

export default Profile;

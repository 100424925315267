import React, { useState } from "react";
import Navbar from "../../components/Navbar/Navbar";
import Box from "@mui/material/Box";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import HeaderBar from "../../components/HeaderBar/HeaderBar";
import MarkFavorite from "../../components/MarkFavorite/MarkFavorite";
import Pagination from "../../components/Pagination/Pagination";

const ViewJob = () => {
  const navigate = useNavigate();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [isOpen, setisOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [pageSize, setpageSize] = useState(10);
  const [totalCounts, setTotalCounts] = useState(100);
  const totalPages = Math.ceil(totalCounts / pageSize);
  const [currentPage, setCurrentPage] = useState(1);
  const activetab = "jobview";

  const handlePageChange = async (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
      window.scrollTo(0, 0);
    }
  };

  const toggleDrawer = () => {
    setOpenDrawer(!openDrawer);
  };

  const searchTextChange = (event) => {
    setSearchText(event.target.value);
  };

  const clearSearch = async () => {
    setSearchText("");
  };

  const navbarOpen = false;

  return (
    <div>
      <Box sx={{ display: "flex", backgroundColor: "#f3f5f9" }}>
        <Navbar data={activetab} navbarOpen={navbarOpen} />
        <Box
          className="px-6 pt-2"
          component="main"
          sx={{ flexGrow: 1, height: "100vh" }}
        >
          <div className="flex flex-col  gap-5">
            {/* <div className="mb-3">
                <HeaderBar title="Job List" toggleDrawer={toggleDrawer} />
              </div> */}
            <div class="flex flex-row mt-2 justify-between w-full">
              <div class="flex flex-row items-center gap-4 w-full">
                <button
                  onClick={() => navigate(-1)}
                  className="flex gap-3 items-center text-[15px] font-medium "
                >
                  <img style={{ width: "16px" }} src="/images/arrow-left.svg" />
                  Back
                </button>
                <p style={{ cursor: "pointer" }} className="text-[15px] font-medium text-[#21D6AA]">
                  JOB ID: JD99
                </p>
                <text className=" text-black">SOFTWARE ENGINEER</text>
              </div>
              <div className="flex w-full items-center gap-3">
                <div
                  className="search-container"
                  style={{
                    position: "relative",
                    display: "flex",
                    alignItems: "center",
                    width: "500px",
                  }}
                >
                  <input
                    type="text"
                    id="search"
                    placeholder="Search Job"
                    style={{
                      border: "none",
                      outline: "none",
                      height: "40px",
                      paddingLeft: "10px",
                      width: "200px",
                      flex: 1,
                    }}
                    value={searchText}
                    onChange={searchTextChange}
                    // onKeyDown={(event) => {
                    //   if (event.key === "Enter") btnSearch();
                    // }}
                    // readOnly={isPopupOpen}
                  />

                  {searchText && (
                    <button
                      style={{
                        position: "absolute",
                        right: "40px",
                        border: "none",
                        outline: "none",
                        background: "transparent",
                        height: "40px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                      }}
                      onClick={clearSearch}
                    >
                      <img
                        src="/images/greenCross1.svg"
                        style={{ width: "10px", height: "10px" }}
                        alt="Clear"
                      />
                    </button>
                  )}

                  <button
                    style={{
                      border: "none",
                      outline: "none",
                      height: "40px",
                      paddingTop: "9px",
                    }}
                    type="submit"
                    // onClick={() => btnSearch()}
                  >
                    <img
                      style={{ background: "inherit" }}
                      src="/images/Search Icon.svg"
                    />
                  </button>
                </div>
                <div className="flex gap-3 ">
                  <text className="text-gray-500 ">Opened 2 Days Ago </text>
                  <div>
                    <MarkFavorite imgUrl={"/images/bookmark.svg"} />
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full  flex gap-6 h-[90vh] overflow-auto">
              <div className="w-[20%] shadow-lg  bg-white px-4 py-3  h-full">
                <div className="flex flex-col gap-2">
                  <span className="flex justify-between mt-3">
                    <text className="text-[#21D6AA] text-[15px] font-medium">
                      All Candidates
                    </text>
                    <text className="text-[#708EA4] text-[15px] font-normal">
                      124
                    </text>
                  </span>

                  <span className="flex justify-between mt-3">
                    <text className=" text-[15px] font-medium">
                      Active Candidates
                    </text>
                    <text className="text-[#708EA4] text-[15px] font-normal">
                      112
                    </text>
                  </span>

                  <hr className="h-[2px] w-full my-3 " color="#EDF4FF" />

                  <div className="flex flex-col gap-3">
                    <text className="text-[#708EA4] text-[15px] font-medium">
                      STATUS
                    </text>
                    <div className="flex flex-col gap-3">
                      <div className="flex justify-between">
                        <div className="flex gap-3 items-center">
                          <input
                            type="checkbox"
                            name="agency"
                            className="items-center w-4 h-4 accent-primary"
                            // value={items?.agencyid}
                            checked={false}
                            // onChange={() => setSelectedAgency(items)}
                          />
                          <label className="text-[15px] font-normal">
                            {" "}
                            Sourced{" "}
                          </label>
                        </div>
                        <text className="text-[#708EA4] text-[15px] font-normal">
                          50
                        </text>
                      </div>

                      <div className="flex justify-between">
                        <div className="flex gap-3 items-center">
                          <input
                            type="checkbox"
                            name="agency"
                            className="items-center w-4 h-4 accent-primary"
                            // value={items?.agencyid}
                            checked={false}
                            // onChange={() => setSelectedAgency(items)}
                          />
                          <label className="text-[15px] font-normal">
                            {" "}
                            Shortlisted{" "}
                          </label>
                        </div>
                        <text className="text-[#708EA4] text-[15px] font-normal">
                          25
                        </text>
                      </div>

                      <div className="flex justify-between">
                        <div className="flex gap-3 items-center">
                          <input
                            type="checkbox"
                            name="agency"
                            className="items-center w-4 h-4 accent-primary"
                            // value={items?.agencyid}
                            checked={false}
                            // onChange={() => setSelectedAgency(items)}
                          />
                          <label className="text-[15px] font-normal">
                            {" "}
                            Interviewed{" "}
                          </label>
                        </div>
                        <text className="text-[#708EA4] text-[15px] font-normal">
                          10
                        </text>
                      </div>

                      <div className="flex justify-between">
                        <div className="flex gap-3 items-center">
                          <input
                            type="checkbox"
                            name="agency"
                            className="items-center w-4 h-4 accent-primary"
                            // value={items?.agencyid}
                            checked={false}
                            // onChange={() => setSelectedAgency(items)}
                          />
                          <label className="text-[15px] font-normal">
                            {" "}
                            Hired{" "}
                          </label>
                        </div>
                        <text className="text-[#708EA4] text-[15px] font-normal">
                          0
                        </text>
                      </div>

                      <div className="flex justify-between">
                        <div className="flex gap-3 items-center">
                          <input
                            type="checkbox"
                            name="agency"
                            className="items-center w-4 h-4 accent-primary"
                            // value={items?.agencyid}
                            checked={false}
                            // onChange={() => setSelectedAgency(items)}
                          />
                          <label className="text-[15px] font-normal">
                            {" "}
                            Rejected{" "}
                          </label>
                        </div>
                        <text className="text-[#708EA4] text-[15px] font-normal">
                          0
                        </text>
                      </div>
                    </div>
                  </div>
                  <hr className="h-[2px] w-full my-3 " color="#EDF4FF" />

                  <div className="flex flex-col gap-3">
                    <text className="text-[#708EA4] text-[15px] font-medium">
                      SAMPLE FILTER
                    </text>
                    <div className="flex flex-col gap-3">
  {["Filter 1", "Filter 2", "Filter 3"].map((label, index) => (
    <div className="flex justify-between items-center" key={index}>
      <div className="flex gap-3 items-center relative">
       
        <input
          type="radio"
          name="filters"
          className="w-4 h-4 appearance-none border-2 rounded-full  checked:bg-[#21D6AA]
                      focus:ring-1 focus:ring-[#21D6AA]"
        />
       
        <label className="text-[15px] font-normal">{label}</label>
        {/* Small green dot when checked */}
        <span className="absolute w-3 h-3 bg-[#21D6AA] rounded-full left-2.5 top-2.5 
                         opacity-0 checked:opacity-100"></span>
      </div>
    </div>
  ))}
</div>


                  </div>
                </div>
              </div>
              <div
                className="w-[80%] shadow-lg bg-white  max-h-[calc(100vh-70px)] overflow-auto "
                style={{
                  overflow: "auto",
                  WebkitOverflowScrolling: "touch",
                  scrollbarWidth: "thin",
                  msOverflowStyle: "none",
                  "&::-webkit-scrollbar": {
                    display: "none",
                  },
                }}
              >
                <div className="mt-5 px-5 flex gap-5 ">
                  <div className="flex gap-5 ">
                    <div className="flex items-center gap-5 h-20">
                      <input
                        type="checkbox"
                        name="agency"
                        className="items-center w-4 h-4 text-white accent-primary"
                        // value={items?.agencyid}
                        checked={true}
                        // onChange={() => setSelectedAgency(items)}
                      />

                      <img
                        className="w-[80px] h-[80px]"
                        src="/images/profile-icon.svg"
                      />
                    </div>
                  </div>
                  <div className="flex flex-col gap-1.5">
                    <div className="flex gap-3 cursor-pointer">
                      <text className="text-[#21D6AA] text-[15px] font-medium"onClick={ () => navigate("/profile")}>
                        John Doe
                      </text>
                      <span className="bg-[#708EA4] w-20 h-5 flex justify-center rounded-lg items-center">
                        {" "}
                        <text className="text-[#FFFFFF] text-[15px] font-medium">
                          {" "}
                          Sourced
                        </text>
                      </span>
                    </div>
                    <text className="text-[15px] font-normal">
                      Software Engineer
                    </text>
                    <text className="text-[11px] text-[#708EA4] font-normal">
                      Louisville, Kentucky, United States
                    </text>
                    <div className="flex flex-col gap-5">
                      <div className="flex gap-6">
                        <div className=" flex justify-between w-[500px] rounded-md h-auto px-3 py-3 bg-[#EDF4FF] bg-no-repeat bg-clip-padding">
                          <text className="text-[15px] w-[160px] font-medium ">
                            {" "}
                            Experience
                          </text>
                          <div className="flex flex-col gap-2">
                            <span className="flex w-auto gap-3 justify-between">
                              <p className="text-[15px]  font-normal">
                                Senior Software Engineer at Maxdata
                                Incorporation
                              </p>
                              <p className="text-[#708EA4] text-right w-[140px] text-[15px] font-normal">
                                2017-Present
                              </p>
                            </span>

                            <span className="flex w-auto gap-3 justify-between">
                              <p className="text-[15px]  font-normal">
                                {" "}
                                Software Engineer at Trubite
                              </p>
                              <p className="text-[#708EA4] w-[140px] text-right text-[15px] font-normal">
                                2016-2017
                              </p>
                            </span>

                            <span className="flex w-auto gap-3 justify-between">
                              <p className="text-[15px]  font-normal">
                                Software Engineer at Medline
                              </p>
                              <p className="text-[#708EA4] w-[140px] text-right text-[15px] font-normal">
                                2014-2016
                              </p>
                            </span>

                            <span className="flex w-auto gap-3 justify-between">
                              <p className="text-[15px]  font-normal">
                                .Net Engineer at Optum
                              </p>
                              <p className="text-[#708EA4] w-[140px] text-right text-[15px] font-normal">
                                2011-2014
                              </p>
                            </span>

                            <label className="text-[#21D6AA] text-[15px] font-medium ">
                              View More (2)
                            </label>
                          </div>
                        </div>

                        <div className="flex flex-col gap-2 mt-4">
                          <span className="flex gap-1">
                            <img src="/PhoneIcon.svg" />
                            <text className="text-[15px]">
                              +1 (203)-456-7890
                            </text>
                          </span>
                          <span className="flex gap-1">
                            <img src="/EmailIcon.svg" />
                            <text className="text-[15px]">
                              johndoe@gmail.com
                            </text>
                          </span>

                          <label className="text-[15px] font-medium mt-2">
                            Activity
                          </label>
                          <span className="flex gap-1.5">
                            <img src="images/jobicon.svg" />
                            <text className="text-[15px] font-medium text-[#21D6AA]">
                              2 Jobs Applied
                            </text>
                          </span>
                          <span className="flex gap-1.5">
                            <img src="images/noteicone.svg" />
                            <text className="text-[15px] font-medium text-[#21D6AA]">
                              1 Note
                            </text>
                          </span>
                        </div>
                      </div>
                      <div className=" flex w-[500px] h-[89px] rounded-md  bg-[#EDF4FF]  px-3 py-3 bg-no-repeat bg-clip-padding">
                        <text className="text-[15px] w-[160px] font-medium ">
                          {" "}
                          Education
                        </text>
                        <div className="flex flex-col gap-2">
                          <span className="flex w-auto gap-3 justify-between">
                            <p className="text-[15px]  font-normal">
                              University of Kentucky, B.S.
                            </p>
                            <p className="text-[#708EA4] text-right w-[140px] text-[15px] font-normal">
                              1993-1996
                            </p>
                          </span>

                          <span className="flex w-auto gap-3 justify-between">
                            <p className="text-[15px]  font-normal">
                              {" "}
                              Jefferson Community College, Kentucky
                            </p>
                            <p className="text-[#708EA4] w-[140px] text-right text-[15px] font-normal">
                              1989-1993
                            </p>
                          </span>
                        </div>
                      </div>
                      <div className=" flex w-[500px] h-[83px] rounded-md  bg-[#EDF4FF]  px-3 py-3 bg-no-repeat bg-clip-padding">
                        <text className="text-[15px] w-[160px] font-medium ">
                          {" "}
                          Certification
                        </text>
                        <div className="flex flex-col gap-2">
                          <span className="flex w-auto gap-3 justify-between">
                            <p className="text-[15px] w-[240px] font-normal">
                              Professional Software Engineering Master (PSEM),
                              IEEE Computer Society
                            </p>
                            <p className="text-[#708EA4] text-right w-[100px] text-[15px] font-normal">
                              1997
                            </p>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr className="h-[2px] w-full mt-8 " color="#EDF4FF" />

                <div className="mt-5 px-5 flex gap-5 ">
                  <div className="flex gap-5 ">
                    <div className="flex items-center gap-5 h-20">
                      <input
                        type="checkbox"
                        color="white"
                        name="agency"
                        className="items-center w-4 h-4 accent-primary "
                        // value={items?.agencyid}
                        checked={false}
                        // onChange={() => setSelectedAgency(items)}
                      />

                      <img
                        className="w-[80px] h-[80px]"
                        src="/images/profile-icon.svg"
                      />
                    </div>
                  </div>
                  <div className="flex flex-col gap-1.5">
                    <div className="flex gap-3">
                      <text className="text-[#21D6AA] text-[15px] font-medium">
                        John Doe
                      </text>
                      <span className="bg-[#708EA4] w-20 h-5 flex justify-center rounded-lg items-center">
                        {" "}
                        <text className="text-[#FFFFFF] text-[15px] font-medium">
                          {" "}
                          Sourced
                        </text>
                      </span>
                    </div>
                    <text className="text-[15px] font-normal">
                      Software Engineer
                    </text>
                    <text className="text-[11px] text-[#708EA4] font-normal">
                      Louisville, Kentucky, United States
                    </text>
                    <div className="flex flex-col gap-5">
                      <div className="flex gap-4">
                        <div className=" flex justify-between w-[500px] rounded-md h-auto px-3 py-3 bg-[#EDF4FF] bg-no-repeat bg-clip-padding">
                          <text className="text-[15px] w-[160px] font-medium ">
                            {" "}
                            Experience
                          </text>
                          <div className="flex flex-col gap-2">
                            <span className="flex w-auto gap-3 justify-between">
                              <p className="text-[15px]  font-normal">
                                Senior Software Engineer at Maxdata
                                Incorporation
                              </p>
                              <p className="text-[#708EA4] text-right w-[140px] text-[15px] font-normal">
                                2017-Present
                              </p>
                            </span>

                            <span className="flex w-auto gap-3 justify-between">
                              <p className="text-[15px]  font-normal">
                                {" "}
                                Software Engineer at Trubite
                              </p>
                              <p className="text-[#708EA4] w-[140px] text-right text-[15px] font-normal">
                                2016-2017
                              </p>
                            </span>

                            <span className="flex w-auto gap-3 justify-between">
                              <p className="text-[15px]  font-normal">
                                Software Engineer at Medline
                              </p>
                              <p className="text-[#708EA4] w-[140px] text-right text-[15px] font-normal">
                                2014-2016
                              </p>
                            </span>

                            <span className="flex w-auto gap-3 justify-between">
                              <p className="text-[15px]  font-normal">
                                .Net Engineer at Optum
                              </p>
                              <p className="text-[#708EA4] w-[140px] text-right text-[15px] font-normal">
                                2011-2014
                              </p>
                            </span>

                            <label className="text-[#21D6AA] text-[15px] font-medium ">
                              View More (2)
                            </label>
                          </div>
                        </div>

                        <div className="flex flex-col gap-2 mt-4">
                          <span className="flex gap-1">
                            <img src="/PhoneIcon.svg" />
                            <text className="text-[15px]">
                              +1 (203)-456-7890
                            </text>
                          </span>
                          <span className="flex gap-1">
                            <img src="/EmailIcon.svg" />
                            <text className="text-[15px]">
                              johndoe@gmail.com
                            </text>
                          </span>

                          <label className="text-[15px] font-medium mt-2">
                            Activity
                          </label>
                          <span className="flex gap-1.5">
                            <img src="images/jobicon.svg" />
                            <text className="text-[15px] font-medium text-[#21D6AA]">
                              2 Jobs Applied
                            </text>
                          </span>
                          <span className="flex gap-1.5">
                            <img src="images/noteicone.svg" />
                            <text className="text-[15px] font-medium text-[#21D6AA]">
                              1 Note
                            </text>
                          </span>
                        </div>
                      </div>
                      <div className=" flex w-[500px] h-[89px] rounded-md  bg-[#EDF4FF]  px-3 py-3 bg-no-repeat bg-clip-padding">
                        <text className="text-[15px] w-[160px] font-medium ">
                          {" "}
                          Education
                        </text>
                        <div className="flex flex-col gap-2">
                          <span className="flex w-auto gap-3 justify-between">
                            <p className="text-[15px]  font-normal">
                              University of Kentucky, B.S.
                            </p>
                            <p className="text-[#708EA4] text-right w-[140px] text-[15px] font-normal">
                              1993-1996
                            </p>
                          </span>

                          <span className="flex w-auto gap-3 justify-between">
                            <p className="text-[15px]  font-normal">
                              {" "}
                              Jefferson Community College, Kentucky
                            </p>
                            <p className="text-[#708EA4] w-[140px] text-right text-[15px] font-normal">
                              1989-1993
                            </p>
                          </span>
                        </div>
                      </div>
                      <div className=" flex w-[500px] h-[83px] rounded-md  bg-[#EDF4FF]  px-3 py-3 bg-no-repeat bg-clip-padding">
                        <text className="text-[15px] w-[160px] font-medium ">
                          {" "}
                          Certification
                        </text>
                        <div className="flex flex-col gap-2">
                          <span className="flex w-auto gap-3 justify-between">
                            <p className="text-[15px] w-[240px] font-normal">
                              Professional Software Engineering Master (PSEM),
                              IEEE Computer Society
                            </p>
                            <p className="text-[#708EA4] text-right w-[100px] text-[15px] font-normal">
                              1997
                            </p>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <Pagination
                  canNextPage={currentPage < totalPages}
                  canPrevPage={currentPage > 1}
                  totalPages={totalPages}
                  currentPage={currentPage}
                  handlePageChange={handlePageChange}
                  bgcolorClass={"white"}
                />
              </div>
            </div>
          </div>
        </Box>
      </Box>
    </div>
  );
};

export default ViewJob;

import React, { useState,useEffect } from "react";
import { useJobsData } from "../../data/jobsdata";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loading from "../Loading/Loading";

export default function SourceHiringPopup({
 agencyList, 
  handleClosePopup, 
  isPopupOpen, 
  jobid, 
  jobtitle,
  isLoading,
   
}) {

  const {EmailSend } = useJobsData();
  const customername = localStorage.getItem("customername");
  const [selectedAgency, setSelectedAgency] = useState(null); 
  const [LoadingForSend, setLoadingForSend] = useState(false);
  const [subject, setSubject] = useState(
    `Resume Submission for Job: ${jobtitle}`
  );

  useEffect(() => {
    // If jobTitle or customer is dynamic, you can update the subject here when they change
    setSubject(`Resume Submission for Job: ${jobtitle}`);
  }, [selectedAgency, jobtitle]);

  const emailLink = `https://app.talentin.ai/os/agencyid=${selectedAgency?.agencyid}&jobid=${jobid}`; 

  const [body, setBody] = useState(`Dear ${selectedAgency?.agencyname},
    
        Please find resumes for the job "${jobtitle}" by clicking the link below to upload resumes:
        
        ${emailLink}  
        
        Best regards,
        [${customername}]`);

  useEffect(() => {
    setBody( `Dear ${selectedAgency?.agencyname},
    
        Please find resumes for the job "${jobtitle}" by clicking the link below to upload resumes:
        
        ${emailLink}  
        
        Best regards,
        [${customername}]`);
  }, [selectedAgency,jobtitle]);
 


  const to =  selectedAgency?.emailid;
//   selectedAgency?.emailid;


  const formData = new FormData();

      formData.append("to", to);
      formData.append("subject", subject);
      formData.append("message", body);

     

  if (!isPopupOpen) return null;

  

  const handleSend = async () => {
    setLoadingForSend(true); 
  
    try {
      const response = await EmailSend({ formdata: formData });
      const { status, message } = response?.header;
  
      if (status === 200) {
        toast.success(
            <text
              style={{
                font: "rubik regular",
                color: "black",
                fontWeight: "normal",
                fontSize: "15px",
              }}
            >
              Email sent successfully!
            </text>
          );
      } else {
        console.error("Failed to send email:", message);
      }
    } catch (error) {
      console.error("API call to send email failed:", error);
    } finally {
      setLoadingForSend(false); 
    }
  };
  

  const handleCancel = () => {
    handleClosePopup();
  };

 
  return (
    <> 
    <ToastContainer />
    <div
      className="fixed inset-0 flex justify-center items-center bg-gray-500 bg-opacity-50 z-50"
      onClick={handleClosePopup}
    >
      <div
        className="bg-white p-6 rounded-md shadow-lg max-w-sm w-[40%] flex flex-col gap-2"
        onClick={(e) => e.stopPropagation()} 
      >
        {isLoading || LoadingForSend ? <Loading /> : ""}
           <div className="flex justify-end">
                     <img
                            onClick={handleCancel}
                            className="w-3 h-3 cursor-pointer"
                            src="/images/Path 1670.svg"
                            alt="close"
                      />
          </div>
        <div className="flex justify-between pb-3">
          <p className="text-left font-bold text-lg">Select Agency and Send Email</p>
          
        </div>

        <div className="flex flex-col  gap-4 h-[400px] overflow-auto" style={{scrollbarWidth:"thin"}}>
          {agencyList?.map((items,index) => (
            <div key={items?.agencyid} 
            className={`${
                index % 2 === 0
                  ? "bg-[#EDF4FF] border-none"
                  : "bg-white border-none"
              }flex items-center justify-center gap-10`} >
                <div className="flex gap-3 items-center">
              <input
                type="checkbox"
                name="agency"
                className="items-center w-4 h-4 accent-primary"
                value={items?.agencyid}
                checked={selectedAgency?.agencyid === items?.agencyid}
                onChange={() => setSelectedAgency(items)}
              />
             <label> {items.agencyname}</label>
            </div>
            </div>
          ))}
        </div>

        <div className="flex justify-center">
        <button
          type="button"
          className="w-44 py-2 mt-4 flex justify-center bg-primary rounded-md text-white font-medium"
          onClick={handleSend}
        >
          Send
        </button>
        </div>
      </div>
    </div>
    </>
  );
}
